<template>
  <div class="uk-grid-medium" uk-grid>
    <div class="uk-width-5-5">
      <div class="d-flex">
        <nav id="breadcrumbs" class="mb-3">
          <ul>
            <li>
              <router-link :to="{ name: 'company.dashboard' }">
                <i class="uil-home-alt"></i>
              </router-link>
            </li>

            <li>
              <router-link :to="{ name: 'company.contents.all' }">{{ this.$t("collectionDetail.contents") }}
              </router-link>
            </li>
            <li>{{ this.$t("general.add_content") }}</li>
          </ul>
        </nav>
      </div>

      <div class="section-header mb-lg-2 border-0 uk-flex-middle">
        <div class="section-header-left">
          <h2 class="uk-heading-line text-left">
            <span>{{ this.$t("general.new_content") }} </span>
          </h2>
        </div>
      </div>
      <template v-if="errorMessages">
        <div v-for="(error, index) in errorMessages" :key="index" class="uk-alert-danger" uk-alert>
          <a class="uk-alert-close" uk-close></a>
          <p style="color: red">
            <i class="uil-exclamation-triangle"></i> {{ error }}
          </p>
        </div>
      </template>
      <div v-if="successMessage" class="uk-alert-success" uk-alert>
        <a class="uk-alert-close" uk-close></a>
        <p style="color: green">
          <i class="uil-exclamation-triangle"></i> {{ successMessage }}
        </p>
      </div>

      <div v-show="loading">
        <default-loading></default-loading>
      </div>

      <div class="card" v-show="!loading">
        <ul class="uk-child-width-expand uk-tab stepper-buttons" id="uk-switcher-tab"
          uk-switcher="connect: #course-edit-tab ; animation: uk-animation-slide-left-medium, uk-animation-slide-right-medium">
          <li class="uk-active">
            <a class="p-4" href="#" aria-expanded="true"><i class="icon-feather-flag"></i>
              <b>{{ this.$t("general.home") }}</b></a>
          </li>
          <li class="">
            <a class="p-4" href="#" aria-expanded="false"><i class="uil-book-open"></i>
              <b>{{ this.$t("general.content") }}</b></a>
          </li>
          <li>
            <a class="p-4" href="#" aria-expanded="false"><i class="uil-cog"></i>
              <b>{{ this.$t("general.features") }}</b></a>
          </li>
          <li>
            <a class="p-4" href="#" aria-expanded="false"><i class="uil-invoice"></i>
              <b>{{ this.$t("general.overview") }}</b></a>
          </li>
        </ul>

        <form class="card-body">
          <ul class="uk-switcher uk-margin" id="course-edit-tab" style="touch-action: pan-y pinch-zoom">
            <!--tab: özellikler-->
            <li class="uk-active" style="">
              <div class="row">
                <div class="col-xl-9 m-auto">
                  <div class="form-group row mb-3">
                    <label class="col-md-3 col-form-label" for="course_title">{{
                      this.$t("collectionDetail.content_name")
                    }}<span class="required">*</span></label>
                    <div class="col-md-9">
                      <input @input="handleTitleChanges" v-model="form.title" type="text" class="form-control"
                        id="course_title" name="title" :placeholder="$t('messages.title')" required="" />
                    </div>
                  </div>

                  <div class="form-group row domain mb-3">
                    <label class="col-md-3 col-form-label" for="duration">{{ this.$t("general.duration")
                      }}<span class="required">*</span></label>
                    <div class="col-md-4">
                      <div class="d-flex">
                        <input type="number" class="form-control flex-grow-1" id="duration" v-model="hour"
                          placeholder="0" required="" @keypress="isNumber($event)" /><span>{{ $t("general.hour")
                          }}</span>
                      </div>
                    </div>
                    <div class="col-md-5">
                      <div class="d-flex">
                        <input type="number" class="form-control flex-grow-1" id="duration" v-model="minutes"
                          placeholder="0" required="" min="1" max="59" @keypress="isNumber($event)" /><span>{{
                            $t("general.minutes") }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label class="col-md-3 col-form-label" for="short_description">{{ this.$t("general.description")
                      }}</label>
                    <div class="col-md-9">
                      <textarea name="short_description" id="short_description" class="form-control"
                        v-model="form.description"></textarea>
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label class="col-md-3 col-form-label" for="short_description">{{ this.$t("faq.category")
                      }}<span class="required">*</span></label>
                    <div class="col-md-9">
                      <multiselect v-model="form.content_category_id" :placeholder="$t('general.select_a_category')"
                        label="name" track-by="id" :options="formattedContentCategories" :allow-empty="false"
                        :searchable="true" deselectLabel="" selectedLabel="'Seçilmiş'" selectLabel="Seçiniz" />
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label class="col-md-3 col-form-label" for="logo">{{ this.$t("collectionDetail.cover_image")
                      }}<span class="required">*</span></label>
                    <div class="col-md-9">
                      <div class="position-relative d-flex justify-center justify-content-center">
                        <img style="height: 190px" class="rounded img-fluid logoarea" src="@/assets/images/no-photo.png"
                          alt="" />
                        <input type="file" class="d-none" id="logo" accept="image/*" @change="processFile($event)" />
                        <button @click="inputClick()" type="button" class="btn btn-default btn-icon-label"
                          style="top: 50%; position: absolute">
                          <span class="btn-inner--icon">
                            <i class="uil-image"></i>
                          </span>
                          <span class="btn-inner--text">{{
                            $t("general.image_change")
                          }}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="typo__label">{{ this.$t("general.category_tags")
                      }}<span class="required mt-2"></span>
                      <i class="uil-info-circle ml-1"
                        uk-tooltip="title: Yalnızca alfasayısal, kısa çizgiler ve alt çizgilere izin verilir.; pos: right"
                        aria-expanded="false"></i></label>
                    <multiselect v-model="hashtags" :tag-placeholder="$t('general.search_in_menu')"
                      :placeholder="$t('general.search_in_menu')" label="name" track-by="id" selectedLabel="Seçilmiş"
                      selectLabel="Seçmek için enter'a basın" deselectLabel="Kaldırmak için enter'a basın"
                      selectGroupLabel="Grup seçmek için enter'a basın"
                      deselectGroupLabel="Grubun seçimini kaldırmak için enter'a basın" :options="hashtags_options"
                      :multiple="true" :taggable="true" :openDirection="'bottom'" @tag="addTag" @change="(val) => {
                        hashtags = val;
                      }
                        " @close="isClosedHashtagSelector = true" @open="isClosedHashtagSelector = false">

                      <template slot="noOptions"><span>Veri bulunmuyor</span></template>
                    </multiselect>
                    <span class="typo__label form__label text-warning mt-1 ml-1" v-show="isEmptyHashtags()">En az bir
                      değer seçmelisiniz.</span>
                  </div>
                </div>
              </div>
              <div class="uk-card-footer float-right pb-0 pr-0">
                <button class="uk-button uk-button-primary small" type="button" @click="validateAndSwitch(1)">
                  <i class="icon-feather-arrow-right"></i>
                  {{ this.$t("general.save_and_continue") }}
                </button>
              </div>
            </li>
            <!--tab: icerik olustur-->
            <li class="" style="">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group row mb-3" v-if="form.file_category_id && +form.file_category_id.id === 1
                  ">
                    <label class="col-md-4 col-form-label" for="use_content_point">
                      Scorm İçerik Skorunu Kullan
                    </label>
                    <div class="col-md-8">
                      <label class="uk-switch" for="use_content_point">
                        <input type="checkbox" id="use_content_point" v-model="form.use_content_point" />
                        <div class="uk-switch-slider"></div>
                      </label>
                    </div>
                  </div>

                  <div class="form-group row mb-3" v-if="!form.use_content_point">
                    <label class="col-md-4 col-form-label" for="score">{{ this.$t("general.content_score")
                      }}<span class="required">*</span></label>
                    <div class="col-md-8">
                      <input type="number" class="form-control" id="score" min="1" max="100" v-model="form.point"
                        placeholder="100" required="" @keypress="isNumber($event)" />
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group row mb-2 input-checkbox">
                    <div class="col-md-4">
                      <label class="col-form-label mb-1" for="pass">{{ this.$t("general.content_type")
                        }}<span class="required">*</span></label>
                    </div>

                    <div class="col-md-8">
                      <multiselect @input="changeOption" v-model="form.file_category_id" :placeholder="$t('collectionDetail.content_type_placeholder')
                        " label="name" track-by="id" :options="formattedFileTypes" :allow-empty="false"
                        :searchable="true" deselectLabel="" selectLabel="Seçiniz" />
                    </div>
                  </div>
                </div>
                <div class="col-md-12" v-if="form.file_category_id">
                  <h4 class="text-center mt-4">
                    {{ $t(selected_content_type.name) }} Yükle
                    <span class="required">*</span>
                  </h4>
                  <div v-if="selected_content_type.accept === 'id'">
                    <div class="form-group">
                      <input type="number" v-model="form.url" placeholder="ID" min="0" class="form-control" />
                    </div>
                  </div>
                  <div v-else-if="selected_content_type.accept === 'url'">
                    <div class="form-group">
                      <input type="text" v-model="form.url" placeholder="https://www.example.com/document"
                        class="form-control" />
                    </div>
                  </div>
                  <div v-else-if="selected_content_type.accept === 'form' &&
                    selected_content_type.key ===
                    'documents.classroom_education'
                  ">
                    <div class="form-group">
                      <form class="uk-child-width-1-3@s uk-grid-small p-4" uk-grid>
                        <div>
                          <h5 class="uk-text-bold mb-2">
                            Eğitmen Adı<span class="required">*</span>
                          </h5>
                          <multiselect v-model="form.instructor_name" :allow-empty="true" :multiple="false"
                            :options="instructorNames" :searchable="true" :tag-placeholder="$t('general.select')"
                            :placeholder="$t('general.select')" selectLabel="Seçmek için enter'a basın"
                            deselectLabel="Kaldırmak için enter'a basın" :openDirection="'bottom'" :taggable="true"
                            @tag="addInstructorName">

                            <template slot="noOptions"><span>Veri bulunmuyor</span></template>
                          </multiselect>
                        </div>
                        <div>
                          <h5 class="uk-text-bold mb-2">Konaklama Maliyeti</h5>
                          <input type="number" v-model="form.accommodation_cost" min="0" step="any"
                            placeholder="Bir sayı yazın" class="form-control" />
                        </div>
                        <div>
                          <h5 class="uk-text-bold mb-2">
                            Eğitim Yeri<span class="required">*</span>
                          </h5>
                          <multiselect v-model="form.education_place" :allow-empty="true" :multiple="false"
                            :options="educationPlaces" :searchable="true" deselectLabel="" selectLabel="Seçiniz"
                            :tag-placeholder="$t('general.select')" :placeholder="$t('general.select')" :taggable="true"
                            @tag="addEducationPlace">

                            <template slot="noOptions"><span>Veri bulunmuyor</span></template>
                          </multiselect>
                        </div>
                        <div>
                          <h5 class="uk-text-bold mb-2">Eğitim Maliyeti</h5>
                          <input type="number" v-model="form.education_cost" min="0" step="any" class="form-control"
                            placeholder="Bir sayı yazın" />
                        </div>
                        <div>
                          <h5 class="uk-text-bold mb-2">Ulaşım Maliyeti</h5>
                          <input type="number" v-model="form.transportation_cost" min="0" step="any"
                            placeholder="Bir sayı yazın" class="form-control" />
                        </div>
                        <div>
                          <h5 class="uk-text-bold mb-2">
                            Eğitim Yeri Maliyeti
                          </h5>
                          <input type="number" v-model="form.education_place_cost" min="0" step="any"
                            placeholder="Bir sayı yazın" class="form-control" />
                        </div>
                        <div>
                          <h5 class="uk-text-bold mb-2">Kapasite</h5>
                          <input type="number" v-model="form.capacity" min="0" class="form-control"
                            placeholder="Bir sayı yazın" />
                        </div>
                      </form>
                    </div>
                  </div>
                  <div v-else-if="selected_content_type.key === 'documents.check_list_item'
                  ">
                    <div class="form-group">
                      <h5 class="uk-text-bold mb-2">
                        Açıklama Metni <span class="required">*</span>
                      </h5>
                      <textarea name="short_description" id="short_description"
                        v-model="form.content_check_list_item_description" class="form-control"></textarea>
                      <form class="mt-4" v-for="(item, index) in form.content_check_list_items">
                        <h5 class="uk-text-bold mb-2">
                          <i class="uil-check-square"></i> {{ index + 1 }}.
                          Kontrol Maddesi
                          <span class="required">*</span>
                        </h5>
                        <div class="row">
                          <div class="col-md-8">
                            <input type="text" class="form-control" v-model="item['name']" @keydown.enter="onEnter" />
                          </div>
                          <div class="col-md-4" style="text-align: end">
                            <div class="uk-grid uk-grid-small d-flex align-items-center justify-content-between"
                              uk-grid>
                              <div class="d-flex align-items-center">
                                <div class="uk-width-auto">
                                  <label class="uk-switch mb-0">
                                    <input type="checkbox" v-model="item.is_mandatory" />
                                    <div class="uk-switch-slider uk-switch-small"></div>
                                  </label>
                                </div>
                                <div class="uk-width-expand">
                                  <small class="ml-2">İşaretlenmesi zorunludur</small>
                                </div>
                              </div>
                              <button class="btn btn-light bg-light" uk-tooltip="title: Kaldır"
                                @click="removeCheckListItem(index)">
                                <i class="uil-trash"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div>
                      <button class="uk-button uk-button-outline" type="button" style="width: 100%"
                        @click="addCheckListItem()">
                        <i class="uil-plus"></i> Kontrol Ekle
                      </button>
                    </div>
                  </div>
                  <div v-else-if="selected_content_type.key === 'documents.mission'">
                    <div class="row">
                      <div class="col-md-7">
                        <div class="form-group">
                          <h5 class="uk-text-bold mb-2">Görev Açıklaması</h5>
                          <textarea v-model="form.mission_description" name="short_description" id="short_description2"
                            class="form-control"></textarea>
                        </div>
                        <div class="form-group">
                          <h5 class="uk-text-bold mb-2">Cevap Türü</h5>
                          <div class="radio-group d-flex">
                            <div class="form-check mr-3">
                              <input class="form-check-input" type="radio" v-model="isAnswerTypeMission" :value="'file'"
                                id="answer_type_1" />
                              <label class="form-check-label" for="answer_type_1">Dosya Yükleme</label>
                            </div>
                            <div class="form-check mr-3">
                              <input id="answer_type_2" class="form-check-input" type="radio" name="answer_type"
                                v-model="isAnswerTypeMission" :value="'text'" />
                              <label class="form-check-label" for="answer_type_2">Metin Cevabı</label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="radio" v-model="form.completion_rule"
                                :value="'admin_approved'" name="complete_type" id="complete_type_3" />
                              <label class="form-check-label" for="complete_type_3">Admin Onayladığında</label>
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <h5 class="uk-text-bold mb-2">Tamamlanma Kuralı</h5>
                          <div class="radio-group">
                            <div class="form-check mb-1">
                              <input class="form-check-input" v-model="form.completion_rule" type="radio"
                                :value="'answered'" name="complete_type" id="complete_type_1" />
                              <label class="form-check-label" for="complete_type_1">Dosya Yüklendiğinde</label>
                            </div>
                            <div class="form-check mb-1">
                              <input class="form-check-input" v-model="form.completion_rule" type="radio"
                                :value="'correct_answer_given'" name="complete_type" id="complete_type_2" />
                              <label class="form-check-label" for="complete_type_2">Doğru Cevap Verildiğinde</label>
                              <div class="row mt-2" v-if="form.completion_rule === 'correct_answer_given'">
                                <div class="col-md-3">
                                  <label class="mb-0 mt-2" for="correct_answer">Doğru Cevap:</label>
                                </div>
                                <div class="col-md-9">
                                  <input name="correct_answer" id="correct_answer" v-model="form.accepted_right_answer"
                                    type="text" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-5">
                        <h5 class="uk-text-bold mb-2">Görev Dosyası</h5>
                        <div class="d-flex align-items-center">
                          <div class="uk-width-auto">
                            <label class="uk-switch mb-0">
                              <input type="checkbox" v-model="form.should_upload_mission_file" />
                              <div class="uk-switch-slider uk-switch-small"></div>
                            </label>
                          </div>
                          <div class="uk-width-expand">
                            <small class="ml-2">Görev Dosyası Yüklensin</small>
                          </div>
                        </div>
                        <!-- <input type="file" accept="image/*" id="mission_file" @change="dragNdrop($event)">
                        <button type="file" class="btn btn-sm btn-outline-primary mt-4" v-if="form.should_upload_mission_file">
                          <i class="uil-upload-alt"></i> Dosya Yükle
                        </button> -->
                        <div class="uploadOuter" v-if="form.should_upload_mission_file">
                          <label for="uploadFile" class="btn btn-info"><i class="uil-upload"></i> Dosya yükle</label>
                          <strong>{{ $t("general.or") }}</strong>
                          <span class="dragBox">
                            <i :class="selected_content_type.icon"></i>
                            Sürükle
                            <input type="file" :accept="acceptable_file_type" draggable="true" @dragover.prevent
                              @dragenter="drag" @dragleave="drop" id="uploadFile" @change="droppedMission" />
                          </span>
                        </div>
                        <div id="previewMission"></div>
                      </div>
                    </div>


                  </div>
                  <div v-else-if="selected_content_type.key === 'documents.webinar'">
                    <!--webinar-form-->
                    <div>
                      <div class="row webinar-insert-form">
                        <div class="col-md-12">
                          <div class="row">
                            <div class="col-md-3">
                              <h5 class="uk-text-bold mb-2">Webinar Türü</h5>
                              <multiselect @input="changeWebinar($event)" :placeholder="$t('Webinar Türünü Seçiniz')"
                                v-model="webinar_integrationType" label="name" :options="webinar_types" track-by="id"
                                :allow-empty="false" :searchable="true" deselectLabel="" selectLabel="Seçiniz" />
                            </div>
                            <div class="col-md-3">
                              <h5 class="uk-text-bold mb-2">Tarih*</h5>
                              <VueCtkDateTimePicker :format="'YYYY-MM-DD HH:mm'" :no-button-now="true"
                                v-model="form.webinar_starts_at" />
                            </div>

                            <div class="col-md-3">
                              <h5 class="uk-text-bold mb-2">Saat Dilimi</h5>
                              <select name="webinar_timezone" id="webinar_timezone">
                                <option>Europe/Istanbul</option>
                                <!-- <option>(GMT + 3:00) Atina)</option>
                                <option>(GMT + 3:00) Bükreş)</option>
                                <option>(GMT + 3:00) Lefkoşa)</option>
                                <option>(GMT + 3:00) Helsinki)</option> -->
                              </select>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-4">
                              <h5 class="uk-text-bold mb-2">Güvenlik</h5>
                              <input name="webinar_security" id="webinar_security" type="text"
                                v-model="form.webinar_password" />
                              <small>Yalnızca davet baglantısına veya parolaya sahip kullanıcılar toplantıya
                                katılabilir.</small>
                            </div>
                            <!-- <div class="col-md-8 pt-4"> -->
                            <!-- <input type="checkbox" name="webinar_waitingroom" id="webinar_waitingroom"/> -->
                            <!-- <label for="webinar_waitingroom" class="ml-3">Bekleme Odası</label> -->
                            <!-- <small>Yalnızca oturum sahibi tarafından kabul edilen kullanıcılar toplantıya katılabilir.</small> -->
                            <!-- </div> -->
                          </div>
                          <div class="row mt-4">
                            <div class="col-md-12">
                              <!-- <p><input type="checkbox" name="webinar_option_1" id="webinar_option_1" class="mr-1"><label for="webinar_option_1" class="mr-1">Katılımcıların istediği zaman katılmasına izin ver.</label></p> -->
                              <!-- <p><input type="checkbox" name="webinar_option_2" id="webinar_option_2" class="mr-1"><label for="webinar_option_2">Girişte katılımcılaır sessize al.</label></p> -->
                              <p><input type="checkbox" name="webinar_option_3" id="webinar_option_3"
                                  v-model="form.webinar_is_auto_recording_enabled" class="mr-1"><label
                                  for="webinar_option_3">Toplantıyı
                                  otomatik olarak yerel
                                  bilgisayara kaydet.</label></p>
                              <!-- <p><input type="checkbox" name="webinar_option_4" id="webinar_option_4" class="mr-1"><label for="webinar_option_4">Belirli ülkelerden/bölgelerden gelen kullanıcılar için girişini onayla veya engelle.</label></p> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--END webinar-form-->

                  </div>

                  <div v-else-if="selected_content_type.key ===
                    'documents.information_content'
                  ">
                    <div class="row" style="justify-content: center">
                      <div class="form-group">
                        <vue-editor v-model="form.information_content"></vue-editor>
                      </div>
                    </div>
                  </div>

                  <div class="uploadOuter" v-else>
                    <label for="uploadFile" class="btn btn-info"><i class="uil-upload"></i>
                      {{ $t("general.upload") }}</label>
                    <strong>{{ $t("general.or") }}</strong>
                    <span class="dragBox">
                      <i :class="selected_content_type.icon"></i>
                      {{ $t(selected_content_type.key + "_drag_drop") }}
                      <input type="file" :accept="acceptable_file_type" draggable="true" @dragover.prevent
                        @dragenter="drag" @dragleave="drop" id="uploadFile" @change="dropped" />
                    </span>
                  </div>
                  <div id="preview"></div>
                  <div v-if="selected_content_type.key === 'documents.powerpoint_document'" class="row">
                    <span> <label class="col-form-label" style="margin-left:2rem;margin-top:10px">Scorm Paketi Olarak
                        Ayarla</label></span>

                    <input type="checkbox" v-model="is_powerpoint_scorm_file"
                      style="width:2%;margin-top:12px;margin-right:1rem;margin-left:1rem">
                  </div>
                  <div v-if="selected_content_type.key === 'documents.powerpoint_document'" class="row">
                    <span> <label class="col-form-label" style="margin-left:2rem;margin-top:10px">Süreli
                        Pptx</label></span>
                    <i class="uil-info-circle" style="margin-top:10px"
                      uk-tooltip="title: Tamamlama butonu geri sayım bitince aktif hale gelir"></i>
                    <input v-model="is_completion_timeout" type="checkbox"
                      style="width:2%;margin-top:12px;margin-right:1rem;margin-left:1rem">
                    <input v-if="is_completion_timeout" type="number" min="1" v-model="completion_timeout"
                      @keypress="isNumber($event)" style="width:6%" />
                    <span v-if="is_completion_timeout" style=" margin-top: 15px;margin-left: 10px;">{{
                      $t("general.minutes")
                    }}</span>
                  </div>
                  <div v-if="selected_content_type.key === 'documents.video_document'" class="row">
                    <span> <label class="col-form-label" style="margin-left:2rem;margin-top:10px">Video İleri
                        Sarılabilsin</label></span>
                    <input v-model="video_can_be_fast_forwarded" type="checkbox"
                      @input="videoBarChange('start', $event.target.checked)"
                      style="width:2%;margin-top:12px;margin-right:1rem;margin-left:1rem">
                    <span> <label class="col-form-label" style="margin-left:2rem;margin-top:10px">Video Tamamlandığında
                        İleri
                        Sarılabilsin</label></span>
                    <input v-model="video_is_completed_it_can_be_fast_forwarded" type="checkbox"
                      @input="videoBarChange('completed', $event.target.checked)"
                      style="width:2%;margin-top:12px;margin-right:1rem;margin-left:1rem">
                  </div>
                </div>
              </div>
              <div class="uk-card-footer float-right pb-0 pr-0">
                <button class="uk-button uk-button-outline mr-2" type="button" @click="switcherChange(0)">
                  <i class="icon-feather-arrow-left"></i>{{ this.$t("general.previous") }}
                </button>
                <button class="uk-button uk-button-primary small" type="button" @click="validateAndSwitch(2)">
                  <i class="icon-feather-arrow-right"></i>
                  {{ this.$t("general.save_and_continue") }}
                </button>
              </div>
            </li>

            <!--tab: ayarlar-->
            <li>
              <div class="container">
                <div class="row">
                  <div class="col-md-12">
                    <!-- <div class="form-group row mb-2">
                      <label
                        class="col-md-4 col-form-label"
                        for="website_keywords"
                        >İçerik Paylaşımı
                        <i
                          class="uil-info-circle"
                          uk-tooltip="title: lorem ipsum dolor sit amet"
                          title=""
                          aria-expanded="false"
                        ></i
                      ></label>
                      <div class="col-md-8">
                        <label class="uk-switch" for="content_share">
                          <input type="checkbox" id="content_share" v-model="form.share_content" @change="handleContentShareChanges" />
                          <div class="uk-switch-slider"></div>
                        </label>
                      </div>
                    </div> -->
                    <!-- <div class="form-group row mb-2">
                      <label
                        class="col-md-4 col-form-label"
                        for="website_keywords"
                        >{{this.$t('general.experience_sharing')}}
                        <i
                          class="uil-info-circle"
                          uk-tooltip="title: lorem ipsum dolor sit amet"
                          title=""
                          aria-expanded="false"
                        ></i
                      ></label>
                      <div class="col-md-8">
                        <label class="uk-switch" for="experience_share">
                          <input type="checkbox" id="experience_share" v-model="form.share_experience" />
                          <div class="uk-switch-slider"></div>
                        </label>
                      </div>
                    </div> -->
                    <div class="form-group row mb-2">
                      <label class="col-md-4 col-form-label" for="website_keywords">{{
                        this.$t("general.save_and_continue") }}
                        <i class="uil-info-circle"
                          uk-tooltip="title: Kullanıcılar eğitimi tamamladığında seçili sertifikaya sahip olabilirler"></i></label>
                      <div class="col-md-8">
                        <multiselect v-model="form.certificate_id" tag-placeholder="Sertifika Seç"
                          :placeholder="$t('general.select_certificate')" label="name" track-by="id"
                          :options="certificate_items" :allow-empty="true" :searchable="true" deselectLabel=""
                          selectLabel="Seçiniz" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="uk-card-footer float-right pb-0 pr-0">
                  <button class="uk-button uk-button-outline mr-2" type="button" @click="switcherChange(1)">
                    <i class="icon-feather-arrow-left"></i>{{ this.$t("general.previous") }}
                  </button>
                  <button class="uk-button uk-button-primary small" type="button" @click="validateAndSwitch(3)">
                    <i class="icon-feather-arrow-right"></i>
                    {{ this.$t("general.save_and_continue") }}
                  </button>
                </div>
              </div>
            </li>
            <li>
              <div class="row">
                <div class="col-12 my-lg-5">
                  <div class="text-center">
                    <h2 class="mt-0">
                      <i class="icon-feather-check-circle text-success"></i>
                    </h2>
                    <h3 class="mt-0">
                      {{ this.$t("general.content_is_ready") }}
                    </h3>

                    <div class="col-md-6 offset-md-3">
                      <table class="uk-table uk-table-divider result-table">
                        <tbody>
                          <tr>
                            <td class="text-center" colspan="2">
                              <img class="logoarea rounded img-fluid" src="@/assets/images/no-photo.png" alt="" />
                            </td>
                          </tr>
                          <tr>
                            <td class="text-right">
                              {{ this.$t("collectionDetail.content_name") }}
                            </td>
                            <td class="text-left">
                              <b>{{ title }}</b>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-right">
                              {{ this.$t("collectionDetail.content_type") }}
                            </td>
                            <td class="text-left">
                              <b>{{ file_type_selected }}</b>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-right">
                              {{ this.$t("general.category_tags") }}
                            </td>
                            <td class="text-left">
                              <b>{{ hashtags_result }}</b>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-right">
                              {{ this.$t("general.content_share") }}
                            </td>
                            <td class="text-left">
                              <b>{{ content_share }}</b>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div class="uk-card-footer float-right pb-0 pr-0">
                <button class="uk-button uk-button-outline mr-2" type="button" @click="switcherChange(2)">
                  <i class="icon-feather-arrow-left"></i>{{ this.$t("general.previous") }}
                </button>
                <button class="uk-button uk-button-primary small" type="button" @click="saveForm()">
                  <i class="icon-feather-arrow-right"></i>
                  {{ this.$t("general.save_and_continue") }}
                </button>
                <button class="uk-button uk-button-primary small ml-2" type="button" @click="saveForm('assign_page')">
                  <i class="uil-location-arrow"></i>
                  {{ this.$t("general.save_and_assign") }}
                </button>
              </div>
            </li>
          </ul>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/core/services";
import Multiselect from "vue-multiselect";
import axios from "axios";
import module, {
  CONTENT_CATEGORIES,
  CONTENT_CATEGORIES_URL,
  FILE_TYPES,
  FILE_TYPES_URL,
  GET_CONTENT_CATEGORIES,
  GET_FILE_TYPES,
  MODULE_NAME,
} from "@/core/services/store/content_categories.module";
import contentModule, {
  BASE_URL,
  CREATE_ITEM,
  ERROR,
  LOADING,
  MODULE_NAME as CONTENT_MODULE_NAME,
} from "@/core/services/store/content.module";

import certificate_module, {
  BASE_URL as CERTIFICATE_BASE_URL,
  GET_ITEMS as CERTIFICATE_GET_ITEMS,
  ITEMS as CERTIFICATE_ITEMS,
  MODULE_NAME as CERTIFICATE_MODULE_NAME,
} from "@/core/services/store/certificate.module";

import DefaultLoading from "@/view/components/loading/DefaultLoading";
import { VueEditor } from "vue2-editor";

const _CERTIFICATE_MODULE = CERTIFICATE_MODULE_NAME;

export default {
  name: "ContentAdd",
  components: {
    Multiselect,
    DefaultLoading,
    VueEditor,
  },
  data() {
    return {
      is_completion_timeout: false,
      completion_timeout: 1,
      is_powerpoint_scorm_file: false,
      hashtags: [],
      isClosedHashtagSelector: false,
      hashtags_options: [],
      title: "Başlık",
      hashtags_result: "Kategori Etiketleri Seçin",
      content_share: "Kapalı",
      file_type_selected: "İçerik Türünü Seçin",
      successMessage: "",
      errorMessages: [],
      instructorNames: [],
      educationPlaces: [],
      hour: "",
      minutes: "",
      form: {
        title: "",
        duration: "",
        point: 100,
        description: "",
        content_category_id: null,
        file_category_id: null,
        share_content: 0,
        share_experience: 0,
        hashtags: [],
        cover_photo: null,
        files: [],
        url: null,
        use_content_point: false,
        certificate_id: null,
        instructor_name: "",
        accommodation_cost: "",
        education_place: "",
        education_cost: "",
        transportation_cost: "",
        education_place_cost: "",
        capacity: "",
        content_check_list_items: [],
        content_check_list_item_description: "",
        information_content: "",
        mission_description: "",
        answer_type: "",
        completion_rule: "answered",
        mission_file: "",
        should_upload_mission_file: false,
        accepted_right_answer: "",
        webinar_starts_at: null,
        webinar_password: null,
        webinar_is_auto_recording_enabled: false,
        webinar_integration_id: null,
        webinar_timezone: null
      },
      acceptable_file_type: "*",
      selected_content_type: [],
      isAnswerTypeMission: "file",
      webinar_types: [],
      webinar_integrationType: null,
      video_can_be_fast_forwarded: false,
      video_is_completed_it_can_be_fast_forwarded: false
    };
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }

    registerStoreModule(MODULE_NAME, module);
    registerStoreModule(_CERTIFICATE_MODULE, certificate_module);
    registerStoreModule(CONTENT_MODULE_NAME, contentModule);
  },
  computed: {
    loading() {
      return store.getters[CONTENT_MODULE_NAME + "/" + LOADING];
    },
    certificate_items: {
      get() {
        return store.getters[CERTIFICATE_MODULE_NAME + "/" + CERTIFICATE_ITEMS];
      },
      set(value) { },
    },
    formattedFileTypes: {
      get() {
        let results = [];
        if (this.file_types === null) return [];
        for (let i = 0; i < this.file_types.length; i++) {
          let currentItem = this.file_types[i];
          if (currentItem) {
            results.push({
              icon: currentItem.icon,
              name: currentItem.name,
              key: currentItem.key_name,
              accept: currentItem.accept,
              id: currentItem.id,
            });
          }
        }
        return results;
      },
      set(value) { },
    },
    formattedContentCategories: {
      get() {
        let results = [];
        if (this.content_categories === null) return [];
        for (let i = 0; i < this.content_categories.length; i++) {
          let currentItem = this.content_categories[i];
          if (currentItem) {
            results.push({
              icon: currentItem.icon,
              name: currentItem.name,
              id: currentItem.id,
            });
          }
        }
        return results;
      },
      set(value) { },
    },
    file_types: {
      get() {
        return store.getters[MODULE_NAME + "/" + FILE_TYPES];
      },
      set(value) { },
    },
    content_categories: {
      get() {
        return store.getters[MODULE_NAME + "/" + CONTENT_CATEGORIES];
      },
      set(value) { },
    },
    error: {
      get() {
        return store.getters[CONTENT_MODULE_NAME + "/" + ERROR];
      },
      set(value) { },
    },
  },
  methods: {
    videoBarChange(type, event) {
      if (type === 'start' && event) {
        this.video_is_completed_it_can_be_fast_forwarded = false;
      }
      else if (type === 'completed' && event)
        this.video_can_be_fast_forwarded = false;
    },
    changeWebinar(event) {
      this.form.webinar_integration_id = event.id
    },
    dragNdrop(event) {
      let file = event.target.files[0];
      this.form.mission_file = file;
    },
    onEnter(e) {
      e.stopPropagation();
      e.preventDefault();
    },
    removeCheckListItem(index) {
      this.form.content_check_list_items.splice(index, 1);
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode === 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    setHashtags() {
      axios
        .get(`/api/content-hashtags`, {
          headers: this.apiHeaders,
        })
        .then((response) => {
          this.hashtags_options = response.data;
        });
    },
    getCertificates() {
      this.$store.dispatch(
        CERTIFICATE_MODULE_NAME + "/" + CERTIFICATE_GET_ITEMS,
        {
          url: CERTIFICATE_BASE_URL,
          filters: {
            status_id: 1,
            order: "asc",
            sort: "id",
          },
        }
      );
    },
    isEmptyHashtags() {
      return this.isClosedHashtagSelector && this.hashtags.length === 0;
    },
    handleHashtagsSelected() {
      let data = JSON.parse(JSON.stringify(this.hashtags));
      if (data.length > 0)
        this.hashtags_result = data
          .map(function (elem) {
            return elem.name;
          })
          .join(", ");
      else this.hashtags_result = "Kategori Etiketleri Seçin";
      this.form.hashtags = data.map(function (elem) {
        return elem.name;
      });
    },
    changeOption() {
      let id = this.form.file_category_id.id;
      if (id) {
        let ContentType = this.getContentTypeById(id);
        this.file_type_selected = ContentType.name;
        this.acceptable_file_type = ContentType.accept;
        this.selected_content_type = ContentType;
        if (this.selected_content_type.key === 'documents.classroom_education') {
          axios
            .get(`/api/content-classroom-education-hashtags`, {
              headers: this.apiHeaders,
            })
            .then((response) => {
              this.instructorNames = response.data.instructor_name;
              this.educationPlaces = response.data.education_place;
            });
        } else if (this.selected_content_type.key === 'documents.webinar') {
          axios
            .get(`/api/webinar-integrations`, {
              headers: this.apiHeaders,
            })
            .then((response) => {
              response.data.forEach((data) => {
                this.webinar_types.length === 0 ? this.webinar_types.push({ name: data.integration.name, id: data.id + '' }) : null
              })
            });
        }
        this.form.files = [];
        this.form.url = null;
        $("#preview").html("");
        $("#previewMission").html("");
        $("#uploadFile").val("");
      }
    },
    getContentTypeById(id) {
      let data;
      this.formattedFileTypes.forEach((item) => {
        if (item.id === id) {
          data = item;
        }
      });
      return data;
    },
    saveForm(process_type) {
      let form = this.form;
      if (!this.isValidFields("ALL")) return false;

      if (this.acceptable_file_type === "url") {
        if (!form.url.includes("http")) form.url = "https://" + form.url;
      }

      let formData = new FormData();
      if (this.selected_content_type.key === "documents.mission") {
        formData.append("mission_description", form.mission_description);
        formData.append("answer_type", this.isAnswerTypeMission);
        formData.append("completion_rule", form.completion_rule);
        // formData.append("completion_rule", "answered");

        formData.append("mission_file", form.mission_file);
        formData.append(
          "should_upload_mission_file",
          form.should_upload_mission_file
        );
        formData.append("accepted_right_answer", form.accepted_right_answer);
      }
      if (this.selected_content_type.key === "documents.webinar") {
        formData.append("webinar_starts_at", new Date(this.form.webinar_starts_at).toISOString().replace(/.\d+Z$/g, "Z"));
        formData.append("webinar_password", form.webinar_password);
        formData.append("webinar_is_auto_recording_enabled", form.webinar_is_auto_recording_enabled);
        formData.append("webinar_timezone", "Europe/Istanbul");
        formData.append("webinar_integration_id", form.webinar_integration_id);
      }
      if (this.selected_content_type.key === "documents.powerpoint_document") {
        formData.append("is_powerpoint_scorm_file", this.is_powerpoint_scorm_file);
        this.is_completion_timeout ? formData.append("completion_timeout", Number(this.completion_timeout)) : formData.append("completion_timeout", null);
      }
      this.video_is_completed_it_can_be_fast_forwarded ? formData.append("video_is_completed_it_can_be_fast_forwarded", true) : null;
      this.video_can_be_fast_forwarded ? formData.append("video_can_be_fast_forwarded", true) : null;

      formData.append("title", form.title);
      formData.append("point", form.point);
      formData.append("duration", form.duration);
      formData.append("has_url", this.acceptable_file_type === "url" ? 1 : 0);
      formData.append(
        "has_anzera_id",
        this.acceptable_file_type === "id" ? 1 : 0
      );
      formData.append("url", form.url);
      formData.append("description", form.description);
      formData.append("content_category_id", form.content_category_id.id);
      formData.append("file_category_id", form.file_category_id.id);
      formData.append(
        "use_content_point",
        form.use_content_point === true || form.use_content_point === 1 ? 1 : 0
      );
      formData.append("instructor_name", form.instructor_name);
      formData.append("accommodation_cost", form.accommodation_cost);
      formData.append("education_place", form.education_place);
      formData.append("education_cost", form.education_cost);
      formData.append("transportation_cost", form.transportation_cost);
      formData.append("education_place_cost", form.education_place_cost);

      formData.append("capacity", form.capacity);
      this.form.content_check_list_items.forEach((element, index) => {
        formData.append(
          "content_check_list_items[" + index + "][name]",
          element.name
        );

        formData.append(
          "content_check_list_items[" + index + "][is_mandatory]",
          element.is_mandatory === true ? 1 : 0
        );
      });
      formData.append(
        "content_check_list_item_description",
        form.content_check_list_item_description
      );
      if (form.use_content_point) {
        formData.append("point", 0);
      } else {
        formData.append("point", form.point);
      }
      form.hashtags.forEach((item) => formData.append("hashtags[]", item));
      formData.append(
        "share_content",
        form.share_content === true || form.share_content === 1 ? 1 : 0
      );
      formData.append(
        "share_experience",
        form.share_experience === true || form.share_experience === 1 ? 1 : 0
      );
      formData.append("cover_photo", form.cover_photo);
      form.information_content
        ? formData.append("information_content", form.information_content)
        : null;

      if (form.files) formData.append("file", form.files);

      if (form.certificate_id)
        formData.append("certificate_id", form.certificate_id.id);

      store
        .dispatch(CONTENT_MODULE_NAME + "/" + CREATE_ITEM, {
          url: BASE_URL,
          contents: formData,
        })
        .then((response) => {
          $("html, body").animate({ scrollTop: 0 }, "slow");
          if (!this.error) {
            this.successMessage = this.$t("general.successfully_created");

            if (process_type === "assign_page") {
              this.$router.push({
                name: "company.assignments.add",
                params: {
                  data: {
                    id: response.id,
                    title: response.title,
                    name: response.name ?? response.file_category?.key_name,
                  },
                },
              });
            } else {
              this.$router.push({ name: "company.contents.all" });
            }
          } else this.errorMessages.push(this.error);
        });
    },
    validateURL(url) {
      var res = url.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
      );
      return res !== null;
    },
    handleContentShareChanges(e) {
      if (e.target.checked) {
        this.content_share = "Açık";
      } else this.content_share = "Kapalı";
    },
    handleTitleChanges(e) {
      this.title = e.target.value;
    },
    getContentCategories() {
      this.$store.dispatch(MODULE_NAME + "/" + GET_CONTENT_CATEGORIES, {
        url: CONTENT_CATEGORIES_URL,
      });
    },
    getFileCategories() {
      this.$store
        .dispatch(MODULE_NAME + "/" + GET_FILE_TYPES, {
          url: FILE_TYPES_URL,
          filters: {
            exam: true,
          },
        })
        .then((response) => {
        });
    },
    addInstructorName(event) {
      this.instructorNames.push(event);
      this.form.instructor_name = event;
    },
    addEducationPlace(event) {
      this.educationPlaces.push(event);
      this.form.education_place = event;
    },
    addTag(newTag, id = null) {
      const tag = {
        name: newTag,
        id:
          id ??
          "custom_" +
          newTag.substring(0, 2) +
          Math.floor(Math.random() * 10000000),
      };
      this.hashtags_options.push(tag);
      this.hashtags.push(tag);
    },
    processFile(event) {
      if (!event.target.files[0]) return false;
      this.form.cover_photo = event.target.files[0];
      let blob = URL.createObjectURL(this.form.cover_photo);
      let imageHolder = $(".logoarea");
      imageHolder.attr("src", blob);
    },
    inputClick() {
      $("#logo").click();
    },
    dropped(event) {
      let file = event.target.files[0];
      if (!file) return false;

      if (!this.compareTypeCompatibality(file, this.acceptable_file_type)) {
        $("#uploadFile").val("");
        this.errorMessages.push("invalid file type");
        return false;
      }
      this.form.files = event.target.files[0];
      let fileName = URL.createObjectURL(event.target.files[0]);
      let preview = document.getElementById("preview");
      let previewImg = false;
      if (this.acceptable_file_type.includes("pdf"))
        previewImg = document.createElement("embed");
      if (this.acceptable_file_type.includes("image"))
        previewImg = document.createElement("img");
      if (
        file.type.includes("audio") &&
        this.acceptable_file_type.includes("audio")
      )
        previewImg = document.createElement("audio");
      if (
        file.type.includes("video") &&
        this.acceptable_file_type.includes("video")
      )
        previewImg = document.createElement("video");
      if (previewImg && previewImg.controls != undefined)
        previewImg.controls = true;
      let docInfo = document.createElement("p");
      docInfo.innerHTML = this.docInfo(file);
      if (previewImg) previewImg.setAttribute("src", fileName);
      if (previewImg && previewImg.tagName.toUpperCase() == "EMBED") {
        previewImg.style.width = "100%";
        previewImg.style.minHeight = "800px";
        previewImg.setAttribute("type", file.type);
      }
      preview.innerHTML = "";
      preview.appendChild(docInfo);
      if (previewImg) preview.appendChild(previewImg);
      this.drop(event);
    },
    droppedMission(event) {
      let file = event.target.files[0];
      if (!file) return false;

      // if (!this.compareTypeCompatibality(file, this.acceptable_file_type)) {
      //   $("#uploadFile").val("");
      //   this.errorMessages.push("invalid file type");
      //   return false;
      // }
      this.form.mission_file = event.target.files[0];
      let fileName = URL.createObjectURL(event.target.files[0]);
      let preview = document.getElementById("previewMission");
      let previewImg = false;
      if (this.acceptable_file_type.includes("pdf"))
        previewImg = document.createElement("embed");
      if (this.acceptable_file_type.includes("image"))
        previewImg = document.createElement("img");
      if (
        file.type.includes("audio") &&
        this.acceptable_file_type.includes("audio")
      )
        previewImg = document.createElement("audio");
      if (
        file.type.includes("video") &&
        this.acceptable_file_type.includes("video")
      )
        previewImg = document.createElement("video");
      if (previewImg && previewImg.controls != undefined)
        previewImg.controls = true;
      let docInfo = document.createElement("p");
      docInfo.innerHTML = this.docInfo(file);
      if (previewImg) previewImg.setAttribute("src", fileName);
      if (previewImg && previewImg.tagName.toUpperCase() == "EMBED") {
        previewImg.style.width = "100%";
        previewImg.style.minHeight = "800px";
        previewImg.setAttribute("type", file.type);
      }
      preview.innerHTML = "";
      preview.appendChild(docInfo);
      if (previewImg) preview.appendChild(previewImg);
      this.drop(event);
    },
    compareTypeCompatibality(file, acceptableType) {
      return (
        acceptableType.includes(file.type) ||
        acceptableType.includes(file.name.split(".").pop()) ||
        (acceptableType.split("/")[0] == file.type.split("/")[0] &&
          file.type.split("/")[0].toLowerCase() != "application")
      );
    },
    drag(event) {
      event.target.parentNode.className = "draging dragBox";
    },
    drop(event) {
      event.target.parentNode.className = "dragBox";
    },
    docInfo(file) {
      let file_complete_name = file.name;
      let file_name_split = file_complete_name.split(".");
      let file_extension = file_name_split.pop().toLowerCase();
      let file_name = file_name_split.join(".");
      let file_size = this.bytesToSize(file.size);
      return (
        '<div class="file-info"><div>Format: <span>' +
        file_extension.toUpperCase() +
        "</span></div><div>Doküman Adı: <span>" +
        file_name +
        "</span></div><div>Boyut: <span>" +
        file_size +
        "</span></div></div>"
      );
    },
    bytesToSize(bytes) {
      let sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },
    resetMessages() {
      this.successMessage = null;
      this.errorMessages = [];
    },
    isValidFields(fields) {
      let self = this;
      let form = self.form;
      self.resetMessages();
      if (fields === "ALL" || fields === 0) {
        if (form.title.length === 0) {
          return self.showFieldsFailedError(
            0,
            self
              .$t("validation.required_field")
              .replace(":NAME", "{{this.$t('collectionDetail.content_name')}}")
          );
        }

        if (form.duration < 1) {
          return self.showFieldsFailedError(
            0,
            self.$t("validation.required_field").replace(":NAME", "Süre")
          );
        }
        if (!form.content_category_id) {
          return self.showFieldsFailedError(
            0,
            self.$t("validation.required_field").replace(":NAME", "Kategori")
          );
        }
        if (form.cover_photo == null) {
          return self.showFieldsFailedError(
            0,
            self
              .$t("validation.required_field")
              .replace(":NAME", "Kapak Görseli")
          );
        }
        // if (form.hashtags.length === 0) {
        //   return self.showFieldsFailedError(
        //     0,
        //     self
        //       .$t("validation.required_field")
        //       .replace(":NAME", "Kategori Etiketleri")
        //   );
        // }
      }
      if (fields === "ALL" || fields === 1) {
        if (
          form.use_content_point === false &&
          (form.point > 100 || form.point < 1)
        ) {
          return self.showFieldsFailedError(
            1,
            self
              .$t("content.invalid.score_between_1_100")
              .replace(":NAME", "Skor")
          );
        }
        if (!form.file_category_id) {
          return self.showFieldsFailedError(
            1,
            self.$t("validation.required_field").replace(":NAME", "İçerik Türü")
          );
        }
        if (
          this.acceptable_file_type === "url" &&
          (form.url == null || !this.validateURL(form.url))
        ) {
          return self.showFieldsFailedError(
            1,
            self
              .$t("validation.required_field")
              .replace(":NAME", self.$t(self.selected_content_type.key))
          );
        }
        if (
          this.acceptable_file_type !== "form" &&
          this.acceptable_file_type !== "url" &&
          this.acceptable_file_type !== "id" &&
          (form.files.length === 0 ||
            !this.compareTypeCompatibality(
              form.files,
              this.acceptable_file_type
            ))
        ) {
          return self.showFieldsFailedError(
            1,
            self.$t("validation.required_field").replace(":NAME", "URL")
          );
        }

        if (this.acceptable_file_type === "id" && form.url == null) {
          return self.showFieldsFailedError(
            1,
            self
              .$t("validation.required_field")
              .replace(":NAME", "Anzera Eğitimi ID")
          );
        }
        // Sınıf içi eğitim
        if (
          this.acceptable_file_type === "form" &&
          this.selected_content_type.key === "documents.classroom_education"
        ) {
          if (form.instructor_name === null) {
            return self.showFieldsFailedError(
              1,
              self
                .$t("validation.required_field")
                .replace(":NAME", "Eğitmen Adı")
            );
          }

          if (form.education_place === null) {
            return self.showFieldsFailedError(
              1,
              self
                .$t("validation.required_field")
                .replace(":NAME", "Eğitim Yeri")
            );
          }
        }
        if (
          this.selected_content_type.key === "documents.information_content" &&
          (this.form.information_content === "" ||
            !this.form.information_content)
        ) {
          return self.showFieldsFailedError(
            1,
            self
              .$t("validation.required_field")
              .replace(":NAME", "Bilgi İçeriği")
          );
        } else if (
          this.selected_content_type.key === "documents.check_list_item"
        ) {
          if (
            form.content_check_list_item_description === "" ||
            !form.content_check_list_item_description
          ) {
            return self.showFieldsFailedError(
              1,
              self
                .$t("validation.required_field")
                .replace(":NAME", "Açıklama Metni")
            );
          }
          let flag = true;
          this.form.content_check_list_items.every((data) => {
            if (!data.name || data.name === "") {
              flag = false;
              return false;
            } else {
              return true;
            }
          });
          if (!flag) {
            return self.showFieldsFailedError(
              1,
              self
                .$t("validation.required_field")
                .replace(":NAME", "Kontrol Listesi Maddeleri")
            );
          }
        } else if (this.selected_content_type.key === "documents.mission") {
          if (form.mission_description === "" || !form.mission_description) {
            return self.showFieldsFailedError(
              1,
              self
                .$t("validation.required_field")
                .replace(":NAME", "Görev açıklaması")
            );
          }
        }
      }

      return true;
    },
    showFieldsFailedError(index, message) {
      this.errorMessages.push(message);
      this.scrollToTop();
      this.switcherChange(index);
      return false;
    },
    validateAndSwitch(index) {
      if (this.isValidFields(index - 1)) this.switcherChange(index);
    },
    switcherChange(index) {
      UIkit.switcher($("#uk-switcher-tab")[0]).show(index);
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
    durationCalculate() {
      this.form.duration = 0;
      this.minutes > 59 ? (this.minutes = 59) : this.minutes;
      let hour = this.hour ? parseInt(this.hour) * 60 : 0;
      let minutes = this.minutes ? parseInt(this.minutes) : 0;
      this.form.duration = hour + minutes;
    },
    addCheckListItem() {
      let self = this;
      self.form.content_check_list_items.push({
        name: null,
        is_mandatory: false,
      });
    },
    setControlListItems() {
      this.form.content_check_list_items = [
        { name: null, is_mandatory: false },
      ];
    },
  },
  mounted() {
    this.getCertificates();
    this.getContentCategories();
    this.getFileCategories();
    this.setHashtags();
    this.setControlListItems();
  },
  watch: {
    minutes: function (value) {
      if (value) {
        this.durationCalculate();
      }
    },
    hour: function (value) {
      if (value) {
        this.durationCalculate();
      }
    },
    "form.file_category_id": function (value) {
      if (value && value.id !== 1) {
        this.form.use_content_point = false;
      }
    },
    "form.use_content_point": function (value) {
      if (value) {
        this.form.point = 0;
      } else {
        this.form.point = 100;
      }
    },
    hashtags() {
      this.handleHashtagsSelected();
    },
    successMessage: function (val) {
      this.debouncedResetValues();
    },
    errorMessages: function (val) {
      this.debouncedResetValues();
    },
  },
  created: function () {
    this.debouncedResetValues = _.debounce(this.resetMessages, 2500);
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}

.dragBox input[type="file"] {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  top: 0;
  left: 0;
}

.dragBox {
  width: auto;
  padding: 0 10px;
  height: 100px;
  margin: 0 auto;
  position: relative;
  text-align: center;
  font-weight: bold;
  line-height: 95px;
  color: #999;
  border: 2px dashed #ccc;
  display: inline-block;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.uploadOuter strong {
  padding: 0 10px;
}

.uploadOuter {
  text-align: center;
  padding: 20px;
}
</style>

<style>
.multiselect__tag {
  color: white;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #3e416d;
}

.multiselect__tag-icon {
  background-color: transparent;
  line-height: 2.2;
}

.multiselect__tag-icon:after {
  color: #fff;
}

.multiselect__tag-icon:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.multiselect__input {
  border: unset !important;
}

.multiselect__tags .multiselect__input {
  border: unset !important;
}
</style>
